import React, { useState } from 'react';

import ascom from '../gif/ubb.gif';
import terminal from '../gif/androidapp1.gif';
//import htmlCrack from '../gif/html-crack-showcase.gif';
import argif from '../gif/ariot.gif'
import stap from '../gif/movierate.gif';

export default function Showcase() {
  const [more, setMore] = useState(false);

  return (
    <div className="my-12">
      <div className="text-4xl my-4 font-[400]">Showcase</div>
      <div className="grid sm:grid-cols-2 gap-5">
      <a className="flex flex-col flex-1 group" href="https://github.com/jobs-code/AR-IoT-Mini-Project" target="_blank">
          <img className="rounded-t-lg border-x border-t dark:border-gray-600 dark:group-hover:border-gray-400 group-hover:border-gray-400" src={argif} />
          <div className="flex flex-col flex-1 p-3 rounded-b-lg border-x border-b dark:border-gray-600 dark:group-hover:border-gray-400 group-hover:border-gray-400">
            <div className="font-semibold group-hover:underline">AR Based Home Automation (Proof of Concept)</div>
            <div className="text-sm">AR based home automation system using Unity3D, Vuforia Engine, Blynk API</div>
          </div>
        </a>
        <a className="flex flex-col flex-1 group" href="https://github.com/jobs-code/unified_bloodbank_django" target="_blank">
          <img className="rounded-t-lg border-x border-t dark:border-gray-600 dark:group-hover:border-gray-400 group-hover:border-gray-400" src={ascom} />
          <div className="flex flex-col flex-1 p-3 rounded-b-lg border-x border-b dark:border-gray-600 dark:group-hover:border-gray-400 group-hover:border-gray-400">
            <div className="font-semibold group-hover:underline">Unified Blood Bank </div>
            <div className="text-sm">unified bloodbank webapp using django where donors can register and others can view the data according to the place. (deployed on azure)</div>
          </div>
        </a>
        <a className="flex flex-col flex-1 group" href="https://github.com/jobs-code/UiPath-Citizen-Developer-SKILL-A-THON-_-projects" target="_blank">
          <img className="rounded-t-lg border-x border-t dark:border-gray-600 dark:group-hover:border-gray-400 group-hover:border-gray-400" src={stap} />
          <div className="flex flex-col flex-1 p-3 rounded-b-lg border-x border-b dark:border-gray-600 dark:group-hover:border-gray-400 group-hover:border-gray-400">
            <div className="font-semibold group-hover:underline">Movie Rating Finder Bot </div>
            <div className="text-sm">Movie rating finder bot using Uipath part Uipath- citizen developer skillathon</div>
          </div>
        </a>
        <>
            <a className="flex flex-col flex-1 group" href="https://github.com/jobs-code/Android_Virtual-Internship---Android-Application-Development-Using-Kotlin_Mini_project" target="_blank">
              <img className="rounded-t-lg border-x border-t dark:border-gray-600 dark:group-hover:border-gray-400 group-hover:border-gray-400" src={terminal} />
              <div className="flex flex-col flex-1 p-3 rounded-b-lg border-x border-b dark:border-gray-600 dark:group-hover:border-gray-400 group-hover:border-gray-400">
                <div className="font-semibold group-hover:underline">Grocery Application</div>
                <div className="text-sm">Grocery Application in android part of Android Application Development using kotlin internship</div>
              </div>
            </a>
           
          </>

      </div>
      {more && 

            
          <div className="flex flex-col flex-1 p-3 rounded-b-lg border-x border-b dark:border-gray-600 dark:group-hover:border-gray-400 group-hover:border-gray-400">
            <div className="font-semibold group-hover:underline" align="center">Working on it!!! </div>
            <div className="text-sm" align="center">More projects coming soon.</div>
          </div>
       
      }
      {!more &&
        <div className="flex justify-center mt-10 text-xs">
          <button onClick={() => setMore(true)} className="bg-black text-white dark:bg-white dark:text-black p-2">Show More</button>
        </div>
      }
    </div>
  )
}
/* <a className="flex flex-col flex-1 group" href="https://61130061.github.io/HTMLCrack/" target="_blank">
<img className="rounded-t-lg border-x border-t dark:border-gray-600 dark:group-hover:border-gray-400 group-hover:border-gray-400" src={htmlCrack} />
<div className="flex flex-col flex-1 p-3 rounded-b-lg border-x border-b dark:border-gray-600 dark:group-hover:border-gray-400 group-hover:border-gray-400">
  <div className="font-semibold group-hover:underline">HTML Crack</div>
  <div className="text-sm">React web app that can help your visualize HTML code as tree chart.</div>
</div>
</a>*/

//{more && }( used to add more button in project add project inside this tag to insert under more button)