function Footer () {
  return (
    <div className="text-sm md:flex md:justify-between text-center md:text-normal w-full mt-20">
      <div className="my-5 md:m-0"> <a href="https://www.buymeacoffee.com/j0bs"target="_blank" className="px-5 hover:cursor-pointer hover:underline">Like my work?? Buy me a Coffee</a></div>
      <div className="flex justify-center divide-x">
        <a href="https://github.com/jobs-code" target="_blank" className="px-5 hover:cursor-pointer hover:underline">GitHub</a>
        <a href="https://www.linkedin.com/in/iamjobin/" target="_blank" className="px-5 hover:cursor-pointer hover:underline">LinkedIn</a>
        <a href="mailto: hello@jobinabraham.live" className="px-5 hover:cursor-pointer hover:underline">Contact me</a>
      </div>
    </div>
  )
}

export default Footer;