function Skills () {
  return (
    <div className="my-12">
      <div className="text-4xl my-4 font-[400]">Skills</div>
      <div className="grid md:grid-cols-3 grid-cols-2 gap-5">
        <div>
          <div className="font-[500] text-xl my-2">Language</div>
          <ul className="list-disc list-inside px-1">
            <li>Javascript</li>
            <li>HTML</li>
            <li>CSS</li>
            <li>Python</li>
            <li>C</li>
            <li>Java</li>
            <li>SQL</li>
          </ul>
        </div>
        <div>
          <div className="font-[500] text-xl my-2">Frontend</div>
          <ul className="list-disc list-inside px-1">
            <li>ReactJS</li>
            <li>Bootstrap</li>
            <li>Tailwind CSS</li>
          </ul>
        </div>
        <div>
          <div className="font-[500] text-xl my-2">Cloud Platforms</div>
          <ul className="list-disc list-inside px-1">
            <li>Azure</li>
            <li>Google Cloud</li>
            <li>Salesforce</li>
            <li>AWS</li>
          </ul>
        </div>
        <div>
          <div className="font-[500] text-xl my-2">Automation tools</div>
          <ul className="list-disc list-inside px-1">
            <li>Uipath</li>
            <li>Outsystems</li>
            <li>Automation everywhere</li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Skills