import { useEffect, useState } from 'react';

import { GitHub, Moon, LinkedIn } from './Icon';


const texts = ['Software Engineering', 'Web Development', 'Cloud Enthusiast','Blogging','UI Designing'];

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

function Header({ dark, setDark }) {
  const [typing, setTyping] = useState('');

  useEffect(() => {
    const typewriter = async () => {
      for (let x = 0; x < texts.length; x++) {
        const tArr = texts[x].split('');
        for (let i = 0; i < tArr.length; i++) {
          setTyping(texts[x].slice(0, i+1));
          await sleep(40);
        }
        await sleep(3000);
        if (x < texts.length-1) {
          for (let i = tArr.length; i >= 0; i--) {
            setTyping(texts[x].slice(0, i));
            await sleep(40);
          }
          await sleep(1000);
        }
      }
    }

    typewriter();
  }, []);

  return (
    <div>
      <div className="flex gap-3 flex-wrap flex-row-reverse text-sm">
        <div onClick={() => setDark(!dark)} className="p-2 bg-black dark:bg-white hover:shadow-lg hover:cursor-pointer">
          <Moon />
        </div>
        <a href="https://github.com/jobs-code" target="_blank" className="p-2 bg-black dark:bg-white hover:shadow-lg hover:cursor-pointer">
          <GitHub />
        </a>
        <a href="https://www.linkedin.com/in/iamjobin/" target="_blank" className="py-2 px-3 bg-black dark:bg-white hover:shadow-lg hover:cursor-pointer">
          <LinkedIn />
        </a>
        <a href="mailto: hello@jobinabraham.live" className="p-2 bg-black dark:bg-white dark:text-black text-white hover:shadow-lg hover:cursor-pointer">hello@jobinabraham.live</a>
      </div>
      <div className="text-5xl mb-5 mt-8 font-[600]">Jobin Abraham </div>
      <div>Make impossible things possible with technology</div>
      <div className="sm:text-4xl my-3 sm:font-[400] flex text-2xl font-[500]">
      <div className="mr-3">🧑🏻‍💻</div>
        <div>{typing}</div>
        <div className="type-cursor">|</div>
      </div>
      <div>Student, web developer and cloud enthusiast who likes making impossible thing for the better world with code. Now seeking to launch a career in web development. Familiar with development and deployment process. Loves exploring new technologies and solving real world problems.</div>
    </div>
  )
}

export default Header;

